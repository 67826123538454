<template>
  <v-card class="app-tab" flat>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-expansion-panels v-model="panel" focusable>
        <v-expansion-panel class="mt-3">
          <v-expansion-panel-header>
            <span><v-icon class="mr-2" small>mdi-apple</v-icon> IOS Metadata</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="6">
                <v-text-field v-model="ios.name" outlined :disabled="!editable" label="NAME" />
                <v-text-field v-model="ios.marketing_url" :counter="100" :rules="[characterLimit100]" outlined :disabled="!editable" label="MARKETING_URL" />
                <v-text-field v-model="ios.apple_id" outlined :disabled="!editable" label="APPLE_ID" />
                <v-textarea
                  v-model="ios.short_description"
                  :counter="170"
                  :rules="[characterLimit170]"
                  outlined
                  :disabled="!editable"
                  label="SHORT_DESCRIPTION"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="ios.keywords" :counter="80" :rules="[characterLimit80]" outlined :disabled="!editable" label="KEYWORDS" />
                <v-text-field v-model="ios.privacy_url" :counter="100" :rules="[characterLimit100]" outlined :disabled="!editable" label="PRIVACY_URL" />
                <v-text-field v-model="ios.support_url" :counter="100" :rules="[characterLimit100]" outlined :disabled="!editable" label="SUPPORT_URL" />
                <v-textarea v-model="ios.description" :counter="4000" :rules="[characterLimit4k]" outlined :disabled="!editable" label="DESCRIPTION" />
              </v-col>
            </v-row>
            <btn-card-actions class="pb-0" :editable.sync="editable" @save="saveEdit" @cancel="cancelEdit()" />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel class="mt-3">
          <v-expansion-panel-header>
            <span><v-icon class="mr-2" small>android</v-icon> Android Metadata</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="6">
                <v-text-field v-model="android.title" outlined :disabled="!editable" label="TITLE" />
                <v-textarea
                  v-model="android.short_description"
                  :counter="80"
                  :rules="[characterLimit80]"
                  outlined
                  :disabled="!editable"
                  label="SHORT_DESCRIPTION"
                />
              </v-col>
              <v-col cols="6">
                <v-textarea
                  v-model="android.full_description"
                  :counter="4000"
                  :rules="[characterLimit4k]"
                  outlined
                  :disabled="!editable"
                  label="FULL_DESCRIPTION"
                />
              </v-col>
            </v-row>
            <btn-card-actions class="pb-0" :editable.sync="editable" @save="saveEdit" @cancel="cancelEdit()" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-form>
  </v-card>
</template>

<script>
import { MUTATION_UPDATE_APP_PROPS } from '@/modules/accounts/graphql'

export default {
  components: {
    BtnCardActions: () => import('@/components/base/BtnCardActions.vue')
  },
  props: {
    app: {
      type: Object,
      default: () => {}
    },
    accountId: {
      type: String,
      default: () => ''
    }
  },
  data: () => ({
    editable: false,
    valid: false,
    panel: 0,
    characterLimit80: v => (v || '').length <= 80 || 'Campo não pode ter mais que 80 caractéres',
    characterLimit100: v => (v || '').length <= 100 || 'Campo não pode ter mais que 100 caractéres',
    characterLimit170: v => (v || '').length <= 170 || 'Campo não pode ter mais que 170 caractéres',
    characterLimit4k: v => (v || '').length <= 4000 || 'Campo não pode ter mais que 4000 caractéres',
    ios: {
      keywords: '',
      marketing_url: '',
      privacy_url: '',
      support_url: '',
      description: '',
      short_description: '',
      apple_id: '',
      name: ''
    },
    android: {
      full_description: '',
      short_description: '',
      title: '',
      keystore_link: '',
      aab_link: ''
    }
  }),
  mounted() {
    this.setValues(this.app)
  },
  methods: {
    setValues(app) {
      this.ios.keywords = app?.metadata?.ios?.keywords
      this.ios.marketing_url = app?.metadata?.ios?.marketing_url
      this.ios.privacy_url = app?.metadata?.ios?.privacy_url
      this.ios.support_url = app?.metadata?.ios?.support_url
      this.ios.description = app?.metadata?.ios?.description
      this.ios.short_description = app?.metadata?.ios?.short_description
      this.ios.apple_id = app?.metadata?.ios?.apple_id
      this.ios.name = app?.metadata?.ios?.name
      this.android.full_description = app?.metadata?.android?.full_description
      this.android.short_description = app?.metadata?.android?.short_description
      this.android.title = app?.metadata?.android?.title
      this.android.keystore_link = app?.metadata?.android?.keystore_link
      this.android.aab_link = app?.metadata?.android?.aab_link || ''
    },
    async saveEdit() {
      if (!this.$refs.form.validate()) return this.$snackbar({ message: 'Erro ao validar campos', snackbarColor: '#F44336' })

      const update = {
        channels: {
          app: {
            build: {
              metadata: {
                ios: {
                  ...this.ios
                },
                android: {
                  ...this.android
                }
              }
            }
          }
        }
      }

      try {
        await this.$apollo.mutate({
          mutation: MUTATION_UPDATE_APP_PROPS,
          variables: {
            account_id: this.accountId,
            update
          },
          context: {
            headers: {
              authorization: localStorage.getItem('session_id')
            },
            uri: this.$microservicesUrls['app_config']
          }
        })

        this.$snackbar({ message: 'Alterações salvas', snackbarColor: '#2E7D32' })
        this.$emit('refresh')
        this.editable = false
      } catch (err) {
        this.$snackbar({ message: 'Falha ao salvar alterações', snackbarColor: '#F44336' })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.app-tab {
  margin-top: 20px;
}

.v-text-field {
  margin: 10px 20px 0;
}

.v-btn {
  margin-left: 15px;
}

.v-icon {
  margin-right: 8px;
}
</style>
